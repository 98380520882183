import * as React from "react"
import PropTypes from "prop-types"

function Seo({ description, title, children }) {
  const metaDescription = description || "TODO:Descrição para o website"
  const defaultTitle = "MMV - Advogados"

  return (
    <>
      <title>
        {title === "Home"
          ? defaultTitle
          : defaultTitle
          ? `${title} | ${defaultTitle}`
          : title}
      </title>
      <meta name="description" content={metaDescription} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content={"byMatwos"} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={metaDescription} />
      {children}
    </>
  )
}

Seo.defaultProps = {
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string.isRequired,
}

export default Seo
