import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import SectionHero from "../components/SectionHero"

//import Layout from "../components/Layout"
import Seo from "../components/SEO"

const heroImage = <StaticImage src="../images/404.jpg" alt="404" />

const NotFoundPage = () => (
  <>
    <SectionHero
      title="404: Not Found"
      text="A página em que está a tentar entrar não existe"
      image={heroImage}
      secondTextRight={false}
    />
  </>
)

export const Head = () => <Seo title="404: Not Found" />

export default NotFoundPage
