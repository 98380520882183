import React from "react"
import { colors } from "../assets/icons"
import Blob from "./Blob"

const SectionHero = ({ title, text, image, secondTextRight }) => {
  return (
    <section className="section-hero">
      <div className="hero-text">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <p className="title">{title}</p>
            </div>
            <div className="col-md-5 offset-md-1">
              <p
                className={
                  (secondTextRight ? "text-md-end" : "text-justify") +
                  " second-text"
                }
              >
                {text}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className="hero-image"
        style={
          {
            //backgroundImage: "url(../images/hero-bg.jpg)", //`url(${imageUrl})`,
          }
        }
      >
        {image && image}
      </div>

      <div className="blob">
        <Blob
          size={200}
          color={colors.primaryLight}
          blobShape={3}
          borderWidth={2}
        />
      </div>
    </section>
  )
}

export default SectionHero
